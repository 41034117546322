// side is width size of triangle

import { getAbsoluteDifference, getAverage } from "../../Utils/Maths";
import { randomNumberBetween } from "../../Utils/Random";

export function setTriSide(side, optionsGlobal) {
  switch (side.style) {
    case 'shrink-to-grow':
      return shrinkToGrow(optionsGlobal);
    case 'shrink-from-middle':
      return shrinkFromMiddle(optionsGlobal);

    // rethink this
    // case 'fit-to-bounds':
    //   return side.side * (optionsGlobal.bounds.width / optionsGlobal.canvas.width);
    default:
      return side.side;
  }
}

export function setTriSideVariables(variables) {
  const { random, min, max, side, multiplier } = variables;

  if (!random) return false;

  if (multiplier) {
    return randomNumberBetween(side / multiplier, side * multiplier);
  } else {
    return randomNumberBetween(min, max);
  }
}


function shrinkToGrow(options) {
  const { canvas, y, side } = options;
  const yPercent = y / canvas.height;
  if (side.inverse) {
    return side.side * (1 - yPercent);
  } else {
    return side.side * yPercent;
  }
}

function shrinkFromMiddle(options) {
  const { canvas } = options;
  const halfCanvasWidth = canvas.width / 2;
  const halfCanvasHeight = canvas.height / 2;
  const multiplier = 1.2;

  const distFromCenterX = getAbsoluteDifference(
    options.x,
    halfCanvasWidth
  );
  const distX = 1 - (distFromCenterX / halfCanvasWidth * multiplier);

  const distFromCenterY = getAbsoluteDifference(
    options.y,
    halfCanvasHeight
  );
  const distY = 1 - (distFromCenterY / halfCanvasHeight * multiplier);

  const averageDist = getAverage([distX, distY]);
  return options.side * averageDist;
}
