import { getFinalTriangles, mixLayers, setFinalLayers, setLayers } from "../Components/Layer";
import { layerBase, layerBaseStripe, layerDefault, layerSecondary, layerTertiary } from "../Themes/JamPat";
import { randomPalette } from "../Utils/Palatte";
import { logSeconds } from "../Utils/Time";
import { layerBackground } from "./DemoAttributes/Background";
import { setColors } from "./DemoAttributes/Colors";
import { logStep } from "./DemoAttributes/Logger";

// 9/20/2022
// just see where things are at so far

let canvasDemo, ctxDemo, colorsDemo;
let savedTriangles = [];

const go = true;
let stepCounter = 0;
function countStep() {
  return stepCounter += 1;
}

const startTime = Date.now();
export default async function demo(canvas, ctx) {
  if (!begin(canvas, ctx)) return;

  setDemoAttributes();
  setBackground();
  saveTriangles()
  drawTriangles();

  finish();
}

function begin(canvas, ctx) {
  console.clear();
  logStep(countStep(), go ? 'draw! 🤠' : 'demo paused');
  canvasDemo = canvas;
  ctxDemo = ctx;
  return go;
}

function setDemoAttributes() {
  // set here to keep all layers the same
  const palatte = randomPalette();

  logStep(countStep(), 'set colors');
  colorsDemo = setColors({ palatte: palatte, shuffle: true });
}

function setBackground() {
  logStep(countStep(), 'background');
  // old way
  setLayers(canvasDemo, ctxDemo, [
    layerBackground({ canvas: canvasDemo, colors: colorsDemo })
  ]);
}

// declare triangles separate from demo()
function saveTriangles() {
  logStep(countStep(), 'saving triangles');
  const baseLayers = setDemoLayer(layerBase({ canvas: canvasDemo, colors: colorsDemo }));
  const baseLayers1 = setDemoLayer(
    layerBaseStripe({ canvas: canvasDemo, colors: colorsDemo })
  );
  const defaulLayers = setDemoLayer(
    layerDefault({ canvas: canvasDemo, colors: colorsDemo })
  );
  const secondaryLayers = setDemoLayer(
    layerSecondary({ canvas: canvasDemo, colors: colorsDemo })
  );

  const tertiaryLayers = setDemoLayer(
    layerTertiary({ canvas: canvasDemo, colors: colorsDemo })
  );

  savedTriangles = [
    baseLayers,
    baseLayers1,
    defaulLayers,
    secondaryLayers,
    tertiaryLayers,
    baseLayers1,
  ];
}

let totalTriangles = 0;
function setDemoLayer(layer) {
  const savedTriangleLayer = saveTriangleLayer(layer);
  totalTriangles += savedTriangleLayer.length;

  // just one layer for now
  const mixCount = 1;
  return arrangeLayers([savedTriangleLayer], {
    mixCount,
  });
}

function saveTriangleLayer(layer) {
  return getFinalTriangles(canvasDemo, ctxDemo, layer);
}

// just mix them for now until better ideas come along
function arrangeLayers(layers, options) {
  return mixLayers(layers, options.mixCount)
}

function drawTriangles() {
  logStep(countStep(), 'drawing triangles');
  // new way
  for (var i = 0; i < savedTriangles.length; i++) {
    setFinalLayers(canvasDemo, ctxDemo, savedTriangles[i]);
  }
}

function finish() {
  logSeconds(startTime, 'Done 🏁 \ntotal triangles: ' + totalTriangles);
}





