import { shadeColor } from './Color';

const palattes = [
  // // https://colorhunt.co/palette/ad8b73ceab93e3caa5fffbe9
  // ['#AD8B73', '#CEAB93', '#E3CAA5', '#FFFBE9'],

  // // https://colorhunt.co/palette/48383842855b90b77dd2d79f
  // ['#483838', '#42855B', '#90B77D', '#D2D79F'],

  // // https://colorhunt.co/palette/fa7070fbf2cfc6ebc5a1c298
  // ['#FA7070', '#FBF2CF', '#C6EBC5', '#A1C298'],

  // // https://colorhunt.co/palette/fdeedcffd8a9f1a661e38b29
  // ['#FDEEDC', '#FFD8A9', '#F1A661', '#E38B29'],

  // // https://colorhunt.co/palette/f90716ff5403ffca03fff323
  // ['#F90716', '#FF5403', '#FFCA03', '#FFF323'],

  // // https://colorhunt.co/palette/b1b2ffaac4ffd2daffeef1ff
  // ['#B1B2FF', '#AAC4FF', '#D2DAFF', '#EEF1FF'],

  // // https://colorhunt.co/palette/242f9b646fd49ba3ebdbdffd
  // ['#242F9B', '#646FD4', '#9BA3EB', '#DBDFFD'],

  // // https://colorhunt.co/palette/277bc0ffb200ffcb42fff4cf
  // ['#277BC0', '#FFB200', '#FFCB42', '#FFF4CF'],

  // // https://colorhunt.co/palette/125c133e7c17f4a442e8e1d9
  // ['#125C13', '#3E7C17', '#F4A442', '#E8E1D9'],

  // // new
  // // https://colorhunt.co/palette/f5eddccfd2cffa9494eb1d36
  // ['#F5EDDC', '#CFD2CF', '#FA9494', '#EB1D36'],

  // // https://colorhunt.co/palette/fcf8e894b49fecb390df7861
  // ['#FCF8E8', '#94B49F', '#ECB390', '#DF7861'],

  // // https://colorhunt.co/palette/8fbdd3e4d1b9be8c63a97155
  // ['#8FBDD3', '#E4D1B9', '#BE8C63', '#A97155'],

  // // https://colorhunt.co/palette/931a25e97171ffcb8ef5efef
  // ['#931A25', '#E97171', '#FFCB8E', '#F5EFEF'],

  // https://colorhunt.co/palette/2c36393f4e4fa27b5cdcd7c9
  ['#2C3639', '#3F4E4F', '#A27B5C', '#DCD7C9'],
];

// bug if using more than one palatte
export function randomPalette() {
  const clonePalattes = [...palattes];
  const randomIndex = Math.floor(Math.random() * clonePalattes.length);
  const palatte = clonePalattes[randomIndex];
  const primary = palatte[0];
  const primaryLight = shadePrimaryOffWhite(primary);

  palatte.push(primaryLight);
  return palatte;
}

function shadePrimaryOffWhite(primary) {
  return shadeColor(primary, 90);
}