import { shuffleArray } from "../Utils/Random";
import drawBackground from "./Background";
import drawTriangle from "./Triangle";
import { setTriOptions } from "./Triangle/CustomOptions";
import { addOptionsVariables } from "./Triangle/Variables";

export default function addLayer(canvas, ctx, options) {
  const { type } = options;

  switch (type) {
    case 'background':
      addBackground(canvas, ctx, options);
      break;
    case 'triangles':
      addTriangles(canvas, ctx, options);
      break;
    case 'triangles-final':
      addTrianglesFinal(canvas, ctx, options);
      break;
    case 'brand':
      addBrand(canvas, ctx, options);
      break;
    default:
      throw new Error('layer type not defined');
  }
}

function addBackground(canvas, ctx, options) {
  drawBackground(ctx, options, canvas);
}

function getTriangleLayerOptions(canvas, options) {
  const { count } = options;

  let triangles = [];

  let layerOptionsGlobal = {
    ...options,
    canvas: canvas,
    numberofSides: 3,
  }

  for (let i = 0; i < count; i++) {
    // randomized within layer
    const variableOptions = addOptionsVariables(options);

    // create hash that updates layerOptionsGlobal with variableOptions color only
    const layerOptions = {
      ...layerOptionsGlobal,

      side: {
        ...layerOptionsGlobal.side,
        ...variableOptions.side,
      },

      fillStyle: {
        ...layerOptionsGlobal.fillStyle,
        ...variableOptions.fillStyle,
      },

      strokeStyle: {
        ...layerOptionsGlobal.strokeStyle,
        ...variableOptions.strokeStyle,
      },

      id: i,
    };
    // console.log('layerOptions', layerOptions);
    triangles.push(layerOptions);
  }

  return triangles;
}

function addTriangles(canvas, ctx, options) {
  const triangles = getTriangleLayerOptions(canvas, options);
  for (let i = 0; i < triangles.length; i++) {
    drawTriangle(ctx, triangles[i]);
  }
}

function addTrianglesFinal(canvas, ctx, options) {
  drawTriangle(ctx, options);
}

export function mixLayers(layers, mixCount) {
  const mixedLayers = [];
  for (let i = 0; i < mixCount; i++) {
    for (let j = 0; j < layers.length; j++) {
      mixedLayers.push(layers[j]);
    }
  }
  return shuffleArray(mixedLayers);
}

export function setLayers(canvas, ctx, layers) {
  for (let i = 0; i < layers.length; i++) {
    addLayer(canvas, ctx, layers[i]);
  }
}

export function getFinalTriangles(canvas, ctx, options) {
  const triangles = getTriangleLayerOptions(canvas, options);
  const finalOptions = [];
  // console.log('triangles', triangles);
  for (let i = 0; i < triangles.length; i++) {
    const triangle = triangles[i];
    const customOptions = setTriOptions(ctx, triangle);
    finalOptions.push({
      ...triangle,
      ...customOptions,
      type: 'triangles-final',
    });
  }
  return finalOptions;
}

export function setFinalLayers(canvas, ctx, layers) {
  for (let i = 0; i < layers.length; i++) {
    setLayers(canvas, ctx, layers[i]);
  }
}

function addBrand(canvas, ctx, options) {
  const drawOptions = {
    canvas: canvas,
    yo: 'yo',
    width: 100,
    height: 100,
  }
  // createSierpinskiTriangle(ctx, [0, 10], 10, 1, drawOptions);
  // drawSierpinski(ctx, drawOptions);
}