import { rotateByCompassEastWest } from '../Components/Triangle/Rotate';
import {
  themeDefaultBackLayer,
  themeDefaultLayer,
  themeLargeOpaque,
} from '../Components/Triangle/Theme';
import { shadeColor } from '../Utils/Color';
import { getNegativeOrPositive } from '../Utils/Maths';
import { coinFlip, randomNumberBetween } from '../Utils/Random';

const shadeAmount = -50;
const shadeAmountLight = -5;
const demoFilter = {
  filter: 'opacity(99%) blur(2px)',
  filterFrequency: 0.99,
};

export function layerBase(options) {
  return {...themeLargeOpaque(options), name: 'layer-base'};
}

export function layerBaseStripe(options) {
  const { colors } = options;
  const defaultTheme = layerBase(options);

  return {
    ...defaultTheme,
    name: 'layer-base-stripe',
    count: 3,
    side: {
      ...defaultTheme.side,
      side: 2000,
      variables: {
        random: true,
        min: 5000,
        max: 8000,
      },
    },
    fillStyle: {
      primary: colors.secondary,
      blank: true,
      variables: {
        random: false,
        blank: true,
      },
    },
    strokeStyle: {
      strokeStyle: colors.primary,
      variables: {
        random: false,
      },
    },
    lineWidthRange: {
      ...defaultTheme.lineWidthRange,
      lower: 200,
      upper: 500,
      style: 'fixed',
    },
    filter: {
      ...defaultTheme.filter,
      filter: 'opacity(60%) blur(1px)',
      filterFrequency: 0.99,
    },
  };
}

export function layerDefault(options) {
  const { colors } = options;
  const defaultTheme = themeDefaultLayer(options);
  return {
    ...defaultTheme,
    name: 'layer-default',
    count: 3,
    side: {
      side: 500,
      style: 'fit-to-bounds',
      variables: {
        random: true,
        min: 20,
        max: 20,
      },
    },
    fillStyle: {
      ...defaultTheme.fillStyle,
      primary: colors.primary,
      variables: {
        random: false,
        primary: colors.primary,
        secondary: colors.secondary,
        odds: 0.75,
      },
    },
    strokeStyle: {
      ...defaultTheme.strokeStyle,
      primary: colors.tertiary,
      variables: {
        random: true,
        primary: colors.primary,
        secondary: colors.secondary,
        odds: 0.25,
      },
    },
    lineWidthRange: {
      lower: 2,
      upper: 5,
      style: 'fixed',
    },
    filter: {
      ...defaultTheme.filter,
      filter: 'opacity(50%) blur(5px)',
      filterFrequency: 0.000015,
    },
    rotate: {
      // random: true,
      degrees: getNegativeOrPositive(randomRotation()),
    },
  };
}

export function layerDefaultAlt(options) {
  const { colors } = options;
  const defaultTheme = layerDefault(options);

  return {
    ...defaultTheme,
    count: 3,
    fillStyle: {
      ...defaultTheme.fillStyle,
      primary: colors.primary,
      variables: {
        random: true,
        primary: colors.primary,
        secondary: colors.secondary,
        odds: 0.5,
      },
    },
    lineWidthRange: {
      ...defaultTheme.lineWidthRange,
      lower: 4,
      upper: 6,
      style: 'fixed',
    },
    filter: coinFlip(0.71) ? demoFilter : randomFilter(),
    rotate: {
      random: false,
      // degrees: getNegativeOrPositive(randomRotation()),
      degrees: Math.PI * 1.5,
    },
  };
}

function randomRotation() {
  // triangle top pointing...
  const rotations = [
    // Math.PI * randomNumberBetween(0, 12), // random rotation

    // Math.PI * 2, // north
    Math.PI * 0.5, // east
    // Math.PI, // south
    Math.PI * 1.5, // west
  ];

  const randomIndex = Math.floor(Math.random() * rotations.length);
  return rotations[randomIndex];
}

function randomFilter() {
  return {
    filter: `opacity(${randomNumberBetween(
      10,
      90
    )}%) blur(${randomNumberBetween(0, 3)}px)`,
    filterFrequency: 0.99,
  }
}

export function layerSecondary(options) {
  const { colors } = options;
  const defaultTheme = layerDefaultAlt(options);

  return {
    ...defaultTheme,
    name: 'layer-secondary',
    fillStyle: {
      primary: colors.tertiary,
      blank: true,
      variables: {
        blank: true,
        random: false,
      },
    },
    strokeStyle: {
      strokeStyle: colors.fourth,
      variables: {
        random: false,
      },
    },
    rotate: {
      degrees: Math.PI * 0.5, // east
    },
  };
}

export function layerTertiary(options) {
  const { colors } = options;
  const defaultTheme = layerDefaultAlt(options);

  return {
    ...defaultTheme,
    name: 'layer-secondary',
    fillStyle: {
      primary: colors.tertiary,
      blank: true,
      variables: {
        random: false,
      },
    },
    strokeStyle: {
      strokeStyle: colors.tertiary,
      variables: {
        random: false,
      },
    },
    rotate: {
      degrees: Math.PI * 1.5, // west
    }
  };
}
