export function getHexColorAtPixel(canvas, ctx, options) {
  const { x, y } = options;
  const imageData = getImageData(canvas, ctx);

  return getPixelRGBA(imageData, getPixelIndex(imageData, x, y));
}

// https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/getImageData
function getImageData(canvas, ctx) {
  return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

// return hash to plug into Colors.buildRGBA easily
// => { r:R, g:G, b:B, a:A }
function getPixelRGBA(imageData, index) {
  var i = index * 4, // each pixel has the 4 color values shown in d
    d = imageData.data; // => [R,G,B,A]

  return {
    r: d[i],
    g: d[i + 1],
    b: d[i + 2],
    // not sure why 255 for `a`, but consider exploring if opacity is available to play with
    a: d[i + 3] > 0 ? 1 : 0,
  };
}

function getPixelIndex(imageData, x, y) {
  return y * imageData.width + x;
}
