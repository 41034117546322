import { getFinalTriangles, mixLayers, setFinalLayers, setLayers } from "../Components/Layer";
import { layerBase, layerBaseStripe, layerDefault, layerDefaultAlt, layerSecondary, layerTertiary } from "../Themes/JamPat1";
import { randomPalette } from "../Utils/Palatte";
import { getRandomBoxCoordinates } from "../Utils/Position";
import { logSeconds } from "../Utils/Time";
import { layerBackground } from "./DemoAttributes/Background";
import { setColors } from "./DemoAttributes/Colors";
import { logStep } from "./DemoAttributes/Logger";

// 9/26/2022
// just see where things are at so far again

// going to try to demo range of plots getting defined for each layer
// bounds

let canvasDemo, ctxDemo, colorsDemo, boundsDemo;
let savedTriangles = [];
let layerOptions = {};

const go = true;
let stepCounter = 0;
function countStep() {
  return stepCounter += 1;
}

const startTime = Date.now();
export default async function demo(canvas, ctx) {
  if (!begin(canvas, ctx)) return;

  setDemoAttributes();
  setBackground();
  saveTriangles()
  drawTriangles();

  finish();
}

function begin(canvas, ctx) {
  console.clear();
  logStep(countStep(), go ? 'draw! 🤠' : 'demo paused');
  canvasDemo = canvas;
  layerOptions = { canvas: canvasDemo };
  ctxDemo = ctx;
  return go;
}

function setDemoAttributes() {
  // set here to keep all layers the same
  setDemoAttributeColors();
}

function setDemoAttributeColors() {
  logStep(countStep(), 'set colors');
  // const palatteDemo = randomPalette();
  const colors = setColors({ palatte: randomPalette(), shuffle: true });
  layerOptions.colors = colors;
  return colors;
}

function setDemoAttributeBounds() {
  // logStep(countStep(), 'set bounds');
  const boundsOptions = {
    full: false,
    center: false,
    maxWidth: 200,
    maxHeight: 200,
  };
  boundsDemo = getRandomBoxCoordinates(canvasDemo, boundsOptions);
  layerOptions.bounds = boundsDemo;
  return boundsDemo;
}

function setBackground() {
  logStep(countStep(), 'background');
  // old way
  setLayers(canvasDemo, ctxDemo, [
    layerBackground(layerOptions)
  ]);
}

// declare triangles separate from demo()
function saveTriangles() {
  logStep(countStep(), 'getting triangles');
  const defaultLayers = setCustomLayers('default', 3000);
  const defaultAltLayers = setCustomLayers('defaultAlt', 90);
  const secondaryLayers = setCustomLayers('secondary', 90);
  const tertiaryLayers = setCustomLayers('tertiary', 90);
  const stripeLayer = layerBaseStripe(layerOptions);
  savedTriangles = setDemoLayers([
    ...defaultLayers,
    ...defaultAltLayers,
    ...secondaryLayers,
    ...tertiaryLayers,
    stripeLayer,
  ]);
}

function setCustomLayers(layerType, layerCount) {
  const layers = [];
  for (let i = 0; i < layerCount; i++) {
    const layer = getLayerByType(layerType);
    layers.push(layer);
  }
  return layers;
}

function getLayerByType(layerType) {
  // switch case on layerType
  // return layer
  switch(layerType) {
    case 'base':
      return layerBase(layerOptions);
    case 'default':
      return layerDefault(layerOptions);
    case 'defaultAlt':
      return layerDefaultAlt(layerOptions);
    case 'secondary':
      return layerSecondary(layerOptions);
    case 'tertiary':
      return layerTertiary(layerOptions);
    default:
      return layerDefault(layerOptions);
  }

}

function setDemoLayers(layers) {
  return layers.map((layer) => setDemoLayer(layer));
}

let totalTriangles = 0;
function setDemoLayer(layer) {
  // reset bounds
  layer.bounds = setDemoAttributeBounds()

  const savedTriangleLayer = saveTriangleLayer(layer);
  totalTriangles += savedTriangleLayer.length;

  // just one layer for now
  const mixCount = 1;
  return arrangeLayers([savedTriangleLayer], {
    mixCount,
  });
}

function saveTriangleLayer(layer) {
  return getFinalTriangles(canvasDemo, ctxDemo, layer);
}

// just mix them for now until better ideas come along
function arrangeLayers(layers, options) {
  return mixLayers(layers, options.mixCount)
}

function drawTriangles() {
  logStep(countStep(), 'drawing triangles');
  // new way
  for (var i = 0; i < savedTriangles.length; i++) {
    setFinalLayers(canvasDemo, ctxDemo, savedTriangles[i]);
  }
}

function finish() {
  logSeconds(startTime, 'Done 🏁 \ntotal triangles: ' + totalTriangles);
}





