import { randomHexColor, shadeColor } from "../../Utils/Color";
import { coinFlip } from "../../Utils/Random";

export function setTriStrokeStyle(options) {
  const { strokeStyle } = options;
  const { variables } = strokeStyle;

  if (strokeStyle.random || variables.random) {
    return randomHexColor();
  } else if (variables && variables.shade) {
    // console.log(options.fillStyle)
    // if (options.fillStyle === 'blank') debugger
    return shadeColor(options.fillStyle, variables.shadeAmount);
  } else {
    return strokeStyle.strokeStyle || strokeStyle.color;
  }
}

export function setTriStrokeStyleVariables(variables) {
  const { random, odds, primary, secondary } = variables;

  if (!random) return false;

  return coinFlip(odds) ? primary : secondary;
}
