import { themeDefaultBackground } from "../Components/Background/Theme";
import { getFinalTriangles, mixLayers, setFinalLayers, setLayers } from "../Components/Layer";
import { themeDefaultLayer, defaultColors, themeDefaultBackLayer } from "../Components/Triangle/Theme";
import { assignColorsFromArray, shadeColor } from "../Utils/Color";
import { randomPalette } from "../Utils/Palatte";
import { logSeconds, setTimeStamp } from "../Utils/Time";


// trying to do blanks with the strokestyle taking the pixel color
// to rethink this just scan the pixels first for color
// maybe whilst plotting the coords so it's not a repeat loop


const go = true;

let colorsArray;
const shadeAmount = -50;
const demoFilter = {
  filter: 'blur(0px)',
  filterFrequency: 0.99,
};

function begin() {
  console.clear();
  console.log(go ? 'draw! 🤠' : 'demo paused');
  return go;
}


const startTime = Date.now();
export default async function demo(canvas, ctx) {
  if (!begin()) return;

  colorsArray = randomPalette();

  const colors = setColors({ colors: colorsArray, shuffle: true });

  const lockedTriangles = [
    // one shuffled (the background will not be placed for this one)
    lockInTriangles(
      canvas,
      ctx,
      setColors({ colors: colorsArray, shuffle: true })
    ),

    // one regular
    lockInTriangles(canvas, ctx, colors),
  ];

  // this shows up in the final effect
  setLayers(canvas, ctx, [
    layerBackground({ canvas, colors }),

    // slightly modified back layer
    backLayer1({ canvas, colors }),
  ]);

  const finalLayersTimeStr = 'Calc setting final triangles...';
  const finalLayersTime = setTimeStamp(finalLayersTimeStr);
  for (var i = 0; i < lockedTriangles.length; i++) {
    setFinalLayers(canvas, ctx, lockedTriangles[i]);
  }
  logSeconds(finalLayersTime, finalLayersTimeStr);

  finish();
}

function lockInTriangles(canvas, ctx, colors) {
  const calcTimeStr = 'Calc getting final triangles...';
  const calcTime = setTimeStamp(calcTimeStr);

  // set background
  // and back layer with larger triangles
  // these will be cleared out after
  console.log('setting base layers');
  setLayers(canvas, ctx, [
    layerBackground({ canvas, colors }),
    backLayer({ canvas, colors }),
  ]);
  console.log('base layers set');

  // get default results that only draw over back layer
  // this will take a while until the triangles are placed at the appropriate locations
  console.log('mixing layers');
  const mixedLayers = mixLayers(
    [
      getFinalTriangles(canvas, ctx, layerDefault({ canvas, colors })),
      getFinalTriangles(canvas, ctx, layerDefaultBlank({ canvas, colors }))
    ],
    1
  );
  logSeconds(calcTime, calcTimeStr);
  console.log('layers mixed');

  // clear canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  return mixedLayers;
}

function finish() {
  logSeconds(startTime, 'Done 🏁');
}



function setColors(options) {
  const { colors } = options;

  let colorsSet = { ...defaultColors };

  if (colors) {
    colorsSet = {
      ...colorsSet,
      ...assignColorsFromArray({ colors: colorsArray }),
    };
  }

  if (options.shuffle) {
    colorsSet = assignColorsFromArray({colors: colorsArray, shuffle: true});
  }

  return colorsSet;
}

function layerBackground(options) {
  const { canvas, colors } = options;
  const defaultTheme = themeDefaultBackground({ canvas, colors });

  return {
    ...defaultTheme,
    name: 'background',
    color: colors.fourth,
  };
};


function layerDefault(options) {
  const { colors } = options;
  const defaultTheme = themeDefaultLayer();

  return {
    ...defaultTheme,
    name: 'default',
    count: 20,

    side: {
      ...defaultTheme.side,
      side: 2000,
      variables: {
        random: true,
        min: 30,
        max: 50,
      },
    },
    fillStyle: {
      primary: colors.secondary,
      secondary: colors.secondary,
      tertiary: colors.tertiary,
      pixel: true,
      skip: {
        fillStyle: [colors.fourth],
      },
      variables: {
        blank: true,
        random: false,
        odds: 0.99,
        primary: colors.primary,
        secondary: colors.secondary,
      },
    },
    strokeStyle: {
      strokeStyle: colors.fifth,
      variables: {
        random: false,
        shade: true,
        shadeAmount: shadeAmount,
        shadeFrom: 'fillStyle',
      },
    },
    lineWidthRange: {
      ...defaultTheme.lineWidthRange,
      lower: 2,
      upper: 5,
      style: 'fixed',
    },
    skip: {
      fillStyle: [colors.primary],
      lineWidth: true,
    },
  };
};

function layerDefaultBlank(options) {
  return {
    ...layerDefault(options),
    fillStyle: {
      ...layerDefault(options).fillStyle,
      blank: true,
      variables: {
        ...layerDefault(options).fillStyle.variables,
        blank: true,
      },
    },
  };
}

// primer layer
function backLayer(options) {
  const { colors } = options;
  const defaultBackLayerTheme = themeDefaultBackLayer(options);

  return {
    ...layerDefault(options),
    ...defaultBackLayerTheme,
    count: 3,
    side: {
      ...defaultBackLayerTheme.side,
      side: 3000,
    },
    name: 'back',
    fillStyle: {
      ...layerDefault(options).fillStyle,
      ...defaultBackLayerTheme.fillStyle,
      primary: colors.primary,
      pixel: false,
    },
    strokeStyle: {
      ...defaultBackLayerTheme.strokeStyle,
      strokeStyle: colors.fourth,
      variables: {
        random: false,
      },
    },
    lineWidthRange: {
      ...defaultBackLayerTheme.lineWidthRange,
      lower: 20,
      upper: 500,
      style: 'fixed',
    },
    filter: demoFilter,
    skip: false,
  };
};

function backLayer1(options) {
  const { colors } = options;

  return {
    ...backLayer(options),
    count: 4,
    side: {
      ...backLayer(options).side,
      side: 2000,
    },
    fillStyle: {
      ...backLayer(options).fillStyle,
      primary: shadeColor(colors.primary, shadeAmount),
    },
    filter: {
      filter: 'blur(1px)',
      filterFrequency: 0.99,
    },
  };
}



