import { randomHexColor, rgba2hex } from "../../Utils/Color";
import { getHexColorAtPixel } from "../../Utils/Pixel";
import { coinFlip } from "../../Utils/Random";

export function setTriFillStyle(fillStyle, options = {}, ctx = null) {
  // console.log('fillSteez', fillStyle)
  if (fillStyle.blank) {
    return 'blank';
  } else if (fillStyle.random) {
    return randomHexColor();
  } else if (fillStyle.pixel) {
    // refactor
    const rgba = getHexColorAtPixel(options.canvas, ctx, options);
    // console.log('rgba', rgba);
    const rgbaStr = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
    const hex = rgba2hex(rgbaStr);

    if (fillStyle.skip) {
      const skipFillStyle = fillStyle.skip.fillStyle;
      if (skipFillStyle.indexOf(hex) > -1) {
        return false
      }
    }

    return hex;
  } else {
    return fillStyle.fillStyle || fillStyle.primary;
  }
}

export function setTriFillStyleVariables(variables) {
  const { random, odds, primary, secondary } = variables;

  if (!random) return false;

  return coinFlip(odds) ? primary : secondary;
}
