export function themeDefaultBackground(options) {
  const { canvas, colors } = options;

  return {
    type: 'background',
    width: canvas.width,
    height: canvas.height,
    color: colors.primary,
    fontSize: 48,
    fontFamily: 'Helvetica',
    text: '',
  };
}
