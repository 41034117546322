import { defaultColors } from "../../Components/Triangle/Theme";
import { assignColorsFromArray } from "../../Utils/Color";
import { randomPalette } from "../../Utils/Palatte";

// should these all be separate?

let randomPalatte = randomPalette();

export function setColors(options) {
  const { palatte, shuffle, rotate } = options;

  let colorsSet = { ...defaultColors };

  const assignedColors = palatte || randomPalatte;

  if (palatte) {
    colorsSet = {
      ...colorsSet,
      ...assignColorsFromArray({ colors: assignedColors }),
    };
  }

  if (shuffle) {
    colorsSet = assignColorsFromArray({
      colors: assignedColors,
      shuffle: true,
    });
  }

  return colorsSet;
}

export function rotatePalatte(colors) {
  const first = colors[0];
  const rest = colors.slice(1);
  return [...rest, first];
}
