import { themeDefaultBackground } from "../../Components/Background/Theme";

export function layerBackground(options) {
  const { canvas, colors } = options;
  const defaultTheme = themeDefaultBackground({ canvas, colors });

  return {
    ...defaultTheme,
    name: 'background',
    color: colors.fourth,
  };
}
