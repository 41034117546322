import { coinFlip } from "../../Utils/Random";

export function setTriFilter(options) {
  const { filter, filterFrequency } = options.filter;
  if (options.filter) {
    const odds = filterFrequency || 0.1;
    return coinFlip(odds) ? filter : '';
  } else {
    return '';
  }
}
