import { randomNumberBetween } from "../../Utils/Random";

export function setTriRotate(options) {
  const { rotate } = options;
  if (rotate.degrees) {
    return rotate.degrees;
  } else if (rotate === 'random' || rotate.random) {
    return randomNumberBetween(0, 360);
  } else {
    return 0;
  }
}

// TODO: make these configurable
export function rotateByCompass(options) {
  // triangle top pointing...
  const rotations = [
    // Math.PI * randomNumberBetween(0, 12), // random rotation

    Math.PI * 2, // north
    Math.PI * 0.5, // east
    Math.PI, // south
    Math.PI * 1.5, // west
  ];

  const randomIndex = Math.floor(Math.random() * rotations.length);
  return rotations[randomIndex];
}

export function rotateByCompassEastWest(options) {
  // triangle top pointing...
  const rotations = [
    Math.PI * 0.5, // east
    Math.PI * 1.5, // west
  ];

  const randomIndex = Math.floor(Math.random() * rotations.length);
  return rotations[randomIndex];
}