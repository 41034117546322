// create button component with click event
import React from 'react';
import { randomNumberBetween } from '../Utils/Random';
import './Logo.css';

export default function Logo(props: any) {

  const min = 1;
  const max = 10;
  const deg = randomNumberBetween(min, max);
  const styles = {
    transform: `skew(${deg}deg)`
  };

  return (
    <div className="App-logo-wrapper" style={styles}>
      <div className="App-logo-container">
        <div className="App-logo-row">
          <span>P</span>
        </div>
        <div className="App-logo-row">
          <span></span>
          <span></span>
          <span>P</span>
          <span>P</span>
          <span></span>
          <span></span>
        </div>
        <div className="App-logo-row">
          <span></span>
          <span></span>
          <span>A</span>
          <span></span>
          <span></span>
          <span>T</span>
          <span></span>
          <span></span>
        </div>
        <div className="App-logo-row">
          <span></span>
          <span>A</span>
          <span>A</span>
          <span>T</span>
          <span>T</span>
          <span></span>
        </div>
      </div>
    </div>
  );
}
