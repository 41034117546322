import { randomHexColor } from "../../Utils/Color";

export const defaultColors = {
  primary: randomHexColor(),
  secondary: randomHexColor(),
  tertiary: randomHexColor(),
  fourth: randomHexColor(),
  fifth: randomHexColor(),
};

export function themeDefaultLayer(options = {}) {
  const { colors } = options;
  const themeColors = colors || defaultColors;

  return {
    type: 'triangles',
    count: 100,
    // position: 'middle', 'random'
    position: 'random',
    rotate: 'random',

    side: {
      side: 50,
      // style: '', 'shrink-to-grow', 'shrink-from-middle'
      style: '',
      variables: {
        random: true,
        min: 10,
        max: 50,
      },
    },
    fillStyle: {
      primary: themeColors.primary,
      secondary: themeColors.secondary,
      tertiary: themeColors.tertiary,
      variables: {
        random: true,
        odds: 0.0,
        primary: themeColors.primary,
        secondary: themeColors.secondary,
      },
    },
    strokeStyle: {
      strokeStyle: themeColors.secondary,
      variables: {
        random: false,
      },
    },
    lineWidthRange: {
      lower: 20,
      upper: 45,
      style: 'fixed',
    },
    filter: {
      filter: 'grayscale(0%)',
      filterFrequency: 0.1,
    },
  };
}

// backLayer
export function themeDefaultBackLayer(options) {
  const { colors } = options;
  const themeColors = colors || defaultColors;

  return {
    ...themeDefaultLayer(),
    count: 60,
    side: {
      side: 500,
      style: '',
      variables: {
        random: false,
      },
    },
    fillStyle: {
      primary: themeColors.primary,
      variables: {
        random: false,
        odds: 0.9,
        primary: themeColors.primary,
        secondary: themeColors.secondary,
      },
    },
  };
}

export function themeLargeOpaque(options) {
  const { colors } = options;
  const defaultTheme = themeDefaultLayer();

  return {
    ...defaultTheme,
    name: 'default',
    count: 10,
    side: {
      ...defaultTheme.side,
      side: 2000,
      variables: {
        random: true,
        min: 1000,
        max: 5000,
      },
    },
    fillStyle: {
      primary: colors.secondary,
      variables: {
        random: false,
      },
    },
    strokeStyle: {
      strokeStyle: colors.fifth,
      variables: {
        random: false,
      },
    },
    lineWidthRange: {
      ...defaultTheme.lineWidthRange,
      lower: 2,
      upper: 5,
      style: 'fixed',
    },
    filter: {
      ...defaultTheme.filter,
      filter: 'opacity(50%) blur(2px)',
      filterFrequency: 0.99,
    },
  };
}