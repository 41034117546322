import Demo1 from './Demo1';
import Demo2 from './Demo2';
import caity from './Caity3';
import pat from './DemoPat';
import DemoPatBnW from './DemoPatBnW';
import DemoPatBnW1 from './DemoPatBnW1';
import DemoPatBnW2 from './DemoPatBnW2';
import DemoPatBnW3 from './DemoPatBnW3';
import DemoPatBnW4 from './DemoPatBnW4';
import demoBgImage from './DemoBgImage';
import DemoSkipPixel from './DemoSkipPixel';
import DemoSkipPixel1 from './DemoSkipPixel1';
import DemoSkipPixel01 from './DemoSkipPixel01';
import DemoJam from './DemoJam';
import DemoJam1 from './DemoJam1';

const allDemos = [
  Demo1,
  Demo2,
  caity,
  pat,
  DemoPatBnW,
  DemoPatBnW1,
  DemoPatBnW2,
  DemoPatBnW3,
  DemoPatBnW4,
  demoBgImage,
  DemoSkipPixel,
];

export default function demos() {
  return [
    // Demo1,
    // Demo2,
    // caity,
    // pat,
    // DemoPatBnW,
    // DemoPatBnW1,
    // DemoPatBnW2,
    // DemoPatBnW3,
    // DemoPatBnW4,
    // demoBgImage,
    // DemoSkipPixel,
    // DemoSkipPixel1,
    // DemoSkipPixel01,
    // DemoJam,
    DemoJam1,
  ];
}