import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import './App.css';
import Footer from './Components/Footer';
import Canvas from './Components/Canvas';
import Logo from './Components/Logo';

function App() {
  const [printCanvas, setPrintCanvas] = useState(false);
  const [displayCanvas, setDisplayCanvas] = useState(true);
  const [displayFooter, setDisplayFooter] = useState(true);

  // const canvasHasLoadedRef = useRef(false);

  // useEffect(() => {
  //   // if (printCanvas) {
  //   //   setDisplayCanvas(false);
  //   //   setDisplayFooter(false);
  //   // }
  //   if (!canvasHasLoadedRef.current) return;
  //   console.log('RENDERED');
  // }, [printCanvas]);

  const onRefresh = () => {
    // rerender canvas
    // this.useForceUpdate();
    refreshCanvas()
  };

  function refreshCanvas() {
    console.log('refresh canvas');
    // rerender canvas
    // this.useForceUpdate();
    setDisplayCanvas(false);
    setTimeout(() => {
      setDisplayCanvas(true);
    }, 100);
  }

  const onClickDownload = () => {
    console.log('download canvas');
    setPrintCanvas(true);
    // refreshCanvas();
  }

  const onCanvasPrinted = () => {
    console.log('canvas printed');
    setPrintCanvas(false);
    // canvasHasLoadedRef.current = true;
    setCanvasPrinted(false);
  }

  const setCanvasPrinted = (canvasPrinted) => {
    if (canvasPrinted) {
      setDisplayCanvas(false);
      setDisplayFooter(false);
    } else {
      setDisplayCanvas(true);
      setDisplayFooter(true);
    }
  }

  const onCanvasClicked = () => {
    setDisplayFooter(!displayFooter);
  }

  return (
    <div className="App">
      {/* <div className="App-container"> */}
      {displayCanvas && (
        <Canvas
          printCanvas={printCanvas}
          onCanvasPrinted={onCanvasPrinted}
          // onCanvasPrinted={setPrintCanvas(false)}
          onCanvasClicked={onCanvasClicked}
        />
      )}

      {displayFooter && (
        <Footer onRefresh={onRefresh} onDownload={onClickDownload} />
      )}

      <Logo />
      {/* </div> */}
    </div>
  );
}

export default App;
