// create button component with click event
import React from 'react';
import './Button.css';

export default function Button(props: any) {
  const onClick = (e) => {
    e.target.blur();
    props.onClick();
  }

  return (
    <button className='App-button' onClick={(e) => onClick(e)}>
      {props.text}
    </button>
  );
}
