import { rotationBySides } from '../Utils/Rotation';
import { setTriOptions } from './Triangle/CustomOptions';

const numberOfSides = 3;

export default function drawTriangle(ctx, options) {
  if (options.final) {
    draw(ctx, options);
  } else {
    const finalOptions = setTriOptions(ctx, options);
    draw(ctx, finalOptions);
  }

}

function draw(ctx, options) {
  const { coords } = options;

  beginTriangle(ctx);
  centerTriangle(ctx, coords, numberOfSides);
  rotateTriangle(ctx, options);
  drawTriangleLines(ctx, coords, options);
  styleTriangle(ctx, options);
  completeTriangle(ctx, options);
}

function beginTriangle(ctx) {
  ctx.beginPath();

  // creates snapshot of current state of canvas
  ctx.save();
}

// immediately set the center of the triangle at the x, y position
function centerTriangle(ctx, coords, numberOfSides) {
  // need double of number of sides to account for inner/outer points
  ctx.translate(
    coords.x,
    coords.y + (coords.triHeight / (numberOfSides * 2)) * Math.sqrt(3)
  );
}

// rotate triangle before drawing points
function rotateTriangle(ctx, options) {
  ctx.rotate(options.rotate);
}

function drawTriangleLines(ctx, coords, options) {
  const rotation = rotationBySides(options);
  ctx.moveTo(coords.x0, coords.y0 - coords.triHeight);

  for (let i = 0; i < numberOfSides; i++) {
    ctx.lineTo(0, coords.yOuter);
    ctx.rotate(rotation);
    ctx.lineTo(0, coords.yInner);
    ctx.rotate(rotation);
  }
}

function styleTriangle(ctx, options) {
  ctx.filter = options.filter;
  ctx.strokeStyle = options.strokeStyle;
  ctx.lineWidth = options.lineWidth;
  if (options.fillStyle && !options.fillStyle.blank) {
    ctx.fillStyle = options.fillStyle;
  }
}

function completeTriangle(ctx, options) {
  ctx.closePath();
  ctx.stroke();
  if (options.fillStyle && !options.fillStyle.blank) {
    ctx.fill();
  }

  // reset canvas to previous state
  ctx.restore();
}
