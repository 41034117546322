import { getFinalTriangles, mixLayers, setFinalLayers, setLayers } from "../Components/Layer";
import { backLayer, backLayer1, backLayer2, layerDefault, layerDefaultAlt } from "../Themes/PixelSkip";
import { randomPalette } from "../Utils/Palatte";
import { logSeconds, setTimeStamp } from "../Utils/Time";
import { layerBackground } from "./DemoAttributes/Background";
import { rotatePalatte, setColors } from "./DemoAttributes/Colors";
import { logStep } from "./DemoAttributes/Logger";

// try to improve on this style

// note from blanks attempt
// trying to do blanks with the strokestyle taking the pixel color
// to rethink this just scan the pixels first for color
// maybe whilst plotting the coords so it's not a repeat loop


const go = true;
let stepCounter = 0;
function countStep() {
  stepCounter += 1;
  return stepCounter;
}

function begin() {
  console.clear();
  logStep((stepCounter += 1), go ? 'draw! 🤠' : 'demo paused');
  return go;
}

const startTime = Date.now();
export default async function demo(canvas, ctx) {
  if (!begin()) return;

  // set here to keep all layers the same
  const palatte = randomPalette();
  const palatte1 = rotatePalatte(palatte);
  const palatte2 = rotatePalatte(palatte1);

  logStep(countStep(), 'set colors');
  const colors = setColors({ palatte: palatte, shuffle: true });

  logStep(countStep(), 'lock in triangles');
  const lockedTriangles = []
  // const lockedTriangles = [
  //   // one shuffled (the background will not be placed for this one)
  //   lockInTriangles(
  //     canvas,
  //     ctx,
  //     setColors({ palatte: palatte1, shuffle: false })
  //   ),

  //   // one more
  //   lockInTriangles(
  //     canvas,
  //     ctx,
  //     setColors({ palatte: palatte2, shuffle: false })
  //   ),

  //   // one regular
  //   lockInTriangles(canvas, ctx, colors),
  // ];

  logStep(countStep(), 'set final base layers');



  // this shows up in the final effect
  setLayers(canvas, ctx, [
    layerBackground({ canvas, colors }),
    // mixedLayersBack,
  ]);

  const mixedLayersBack = mixLayers(
    [
      backLayer1({ canvas, colors }),
      backLayer2({ canvas, colors })
    ],
    4
  );
  setLayers(canvas, ctx, mixedLayersBack);

  logStep(countStep(), 'set final locked in layers');

  const finalLayersTimeStr = 'Calc setting final triangles...';
  const finalLayersTime = setTimeStamp(finalLayersTimeStr);
  for (var i = 0; i < lockedTriangles.length; i++) {
    // setFinalLayers(canvas, ctx, lockedTriangles[i]);
  }
  logSeconds(finalLayersTime, finalLayersTimeStr);

  finish();
}

let countTrianglesToLockIn = 0;
function lockInTriangles(canvas, ctx, colors) {
  countTrianglesToLockIn += 1;
  logStep(countStep(), `Locking in triangles: ${countTrianglesToLockIn}`);
  const calcTimeStr = 'Calc getting final triangles...';
  const calcTime = setTimeStamp(calcTimeStr);

  // set background
  // and back layer with larger triangles
  // these will be cleared out after
  logStep(
    countStep(),
    `setting base layers: ${countTrianglesToLockIn}`
  );
  setLayers(canvas, ctx, [
    layerBackground({ canvas, colors }),
    backLayer({ canvas, colors }),
  ]);
  logStep(countStep(), `base layers set: ${countTrianglesToLockIn}`);

  // get default results that only draw over back layer
  // this will take a while until the triangles are placed at the appropriate locations
  logStep(countStep(), `mixing layers: ${countTrianglesToLockIn}`);
  const mixedLayers = mixLayers(
    [
      getFinalTriangles(canvas, ctx, layerDefault({ canvas, colors })),
      getFinalTriangles(canvas, ctx, layerDefaultAlt({ canvas, colors }))
    ],
    3
  );
  logSeconds(calcTime, calcTimeStr);
  logStep(countStep(), `layers mixed: ${countTrianglesToLockIn}`);

  // clear canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  return mixedLayers;
}

function finish() {
  logSeconds(startTime, 'Done 🏁');
}





