import { themeDefaultBackground } from "../Components/Background/Theme";
import { mixLayers, setLayers } from "../Components/Layer";
import { themeDefaultLayer, defaultColors, themeDefaultBackLayer } from "../Components/Triangle/Theme";
import { assignColorsFromArray, shadeColor } from "../Utils/Color";

// pallate
// https://colorhunt.co/palette/2121216d9886d9cab3f6f6f6
const colorsArray = [
  '#212121',
  '#6D9886',
  '#D9CAB3',
  '#F6F6F6',
  '#FFFFFF'
];

const src =
  process.env.PUBLIC_URL +
  [
    // 'ko.png'
    '/kotp.png'
    // '/dallelobster.png',
    // '/pika.jpg',
    // '/mochinobg.png',
    // '/patnobg.png',
    // 'ko1.png',
    // 'konobo.JPG',
    // '/cb3bg.png'
    // '/mac0bg.png'
    // '/goldie-sticker.png'
    // '/celticsbig3.jpg'
    // '/smarf.png'
    // '/bw2.jpeg'
    // '/emashup_anguished+expressionless_white.png',
  ].toString();


const shadeAmount = 50;

const demoFilter = {
  filter: 'blur(5px)',
  filterFrequency: 0.99,
};

function setColors(options) {
  const { colors } = options;

  let colorsSet = { ...defaultColors };

  if (colors) {
    colorsSet = {
      ...colorsSet,
      ...assignColorsFromArray({ colors: colorsArray }),
    };
  }

  if (options.shuffle) {
    colorsSet = assignColorsFromArray({colors: colorsArray, shuffle: true});
  }

  return colorsSet;
}


function layerDefault(options) {
  const { colors } = options;
  const defaultTheme = themeDefaultLayer();

  const sideStyleBig = true;

  return {
    ...defaultTheme,
    name: 'default',
    count: 10,

    side: {
      ...defaultTheme.side,
      side: 2000,
      variables: {
        random: true,
        min: sideStyleBig ? 10 : 5,
        max: sideStyleBig ? 20 : 10,
      },
    },
    fillStyle: {
      primary: colors.secondary,
      secondary: colors.secondary,
      tertiary: colors.tertiary,
      pixel: true,
      skip: {
        fillStyle: [colors.fourth]
      },
      variables: {
        random: false,
        odds: 0.9,
        primary: colors.primary,
        secondary: colors.secondary,
      },
    },
    strokeStyle: {
      strokeStyle: shadeColor(colors.fifth, -100),
      variables: {
        random: false,
      },
    },
    lineWidthRange: {
      ...defaultTheme.lineWidthRange,
      lower: 2,
      upper: 5,
      style: 'fixed',
    },
    skip: {
      fillStyle: [colors.fourth],
      lineWidth: true,
    },
  };
};

// primer layer
function backLayer(options) {
  const { colors } = options;
  const defaultBackLayerTheme = themeDefaultBackLayer(options);

  return {
    ...layerDefault(options),
    ...defaultBackLayerTheme,
    name: 'back',
    fillStyle: {
      ...layerDefault(options).fillStyle,
      ...defaultBackLayerTheme.fillStyle,
      primary: colors.primary,
      pixel: false,
    },
    strokeStyle: {
      ...defaultBackLayerTheme.strokeStyle,
      strokeStyle: colors.fourth,
    },
    lineWidthRange: {
      ...defaultBackLayerTheme.lineWidthRange,
      lower: 20,
      upper: 500,
      style: 'fixed',
    },
    filter: demoFilter,
    skip: false
  };
};

function layerBackground(options) {
  const { canvas, colors } = options;
  const defaultTheme = themeDefaultBackground({ canvas, colors });

  return {
    ...defaultTheme,
    name: 'background',
    color: colors.fourth,
  };
};

export default async function demo(canvas, ctx) {
  const colors = setColors({ colors: colorsArray, shuffle: false });
  setLayers(canvas, ctx, [
    layerBackground({ canvas, colors }),

    // backLayer({ canvas, colors }),
  ]);
  drawImageToBackground(canvas, ctx)
}

function setLayersRemaining(canvas, ctx) {
  const colors = setColors({ colors: colorsArray, shuffle: false });

  const mixedLayers = mixLayers(
    [layerDefault({ canvas, colors })],
    1
  );

  setLayers(canvas, ctx, mixedLayers);
  console.log('all layers set!');
}

// TODO: make Utils/Image.js
// and make onload logic work
// can't return whole img, so just height for now to trigger callback
function imageDownlaod(canvas, ctx, src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      displayImage(canvas, ctx, img);
      resolve(img.height);
    };
    img.onerror = reject;
    img.src = src;
  });
}

function displayImage(canvas, ctx, img) {
  var wrh = img.width / img.height;
  var newWidth = canvas.width;
  var newHeight = newWidth / wrh;
  if (newHeight > canvas.height) {
    newHeight = canvas.height;
    newWidth = newHeight * wrh;
  }
  var xOffset =
    newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
  var yOffset =
    newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
  ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
}

async function drawImageToBackground(canvas, ctx) {
  console.log('downloading image...');
  await imageDownlaod(canvas, ctx, src).then((height) => {
    console.log('downloaded image!');
    setLayersRemaining(canvas, ctx);
  });
}