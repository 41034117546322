import { setCoords } from "./Coords";
import { setTriFillStyle } from "./FillStyle";
import { setTriFilter } from "./Filter";
import { setTriLineWidth } from "./LineWidth";
import { setTriRotate } from "./Rotate";
import { setTriStrokeStyle } from "./StrokeStyle";

export function setTriOptions(ctx, options) {
  options.coords = setCoords(options);
  // set styles

  let fillStyle;
  if (!options.fillStyle.blank) {
    // keep resetting until ready
    fillStyle = setTriFillStyle(options.fillStyle, options, ctx);
    while (!fillStyle && !reasonToSkip(options)) {
      options.coords = setCoords(options);
      fillStyle = setTriFillStyle(options.fillStyle, options, ctx);
    }
    if (fillStyle && isHexCode(fillStyle)) {
      options.fillStyle = fillStyle;
    }
  }

  options.strokeStyle = setTriStrokeStyle(options);
  options.filter = setTriFilter(options);
  options.lineWidth = setTriLineWidth(options);
  options.rotate = setTriRotate(options);

  options.final = true;

  return options;
}

// TODO: refactor for dynamic options
// other options:
// - 'blank'
function isHexCode(str) {
  return str[0] === '#';
}

function reasonToSkip(options) {
  return intentionallyBlank(options);
}

function intentionallyBlank(options) {
  return options.fillStyle.blank;
}