import { posCoordsMiddle, posCoordsRandom } from "../../Utils/Position";

function defaultBounds(canvas) {
  return {
    x: 0,
    y: 0,
    width: canvas.width,
    height: canvas.height,
  };
};

export function setTriPosition(options) {
  const { canvas, bounds } = options;

  const coordBounds = bounds || defaultBounds(canvas);
  // console.log('coordBounds', coordBounds);

  switch (options.position) {
    case 'random':
      // console.log('random', posCoordsRandom(coordBounds));
      return posCoordsRandom(coordBounds);
    case 'middle':
      return posCoordsMiddle(coordBounds);
    default:
      console.log('please define a position');
      return;
  }
}
