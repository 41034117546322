import { randomNumberBetween } from "./Random";

function defaultBounds(canvas) {
  return {
    x: 0,
    y: 0,
    width: canvas.width,
    height: canvas.height,
  };
};

function centerBounds(canvas) {
  return {
    x: canvas.width / 2,
    y: canvas.height / 2,
    width: 0,
    height: 0,
  };
};

export function posCoordsMiddle(bounds) {
  const { width, height } = bounds;

  return {
    x: width / 2,
    y: height / 2,
  };
}

export function posCoordsRandom(bounds) {
  const { x, y, width, height } = bounds;

  // should be the same if 0 or default full canvas bounds
  // going negative and double to make sure it fits evenly dispersed
  // within the bounds
  // was pushing down to the right

  // console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`);
  // if (x < 1) console.log(`x: ${x}`);
  // if (y < 1) console.log(`y: ${y}`);
  // if (x < 1 && y < 1) debugger
  return {
    x: randomNumberBetween(x, x + width),
    y: randomNumberBetween(y, y + height)
  };
}

// get random box coordinates on canvas
export function getRandomBoxCoordinates(canvas, options) {
  const { full, center, maxWidth, maxHeight } = options;

  // quick and dirty override if desired
  if (full) return defaultBounds(canvas);
  if (center) {
    console.log('center', centerBounds(canvas));
    return centerBounds(canvas);
  }

  // keep margin potentially off on upper left
  // this keeps a part of the box on the canvas
  // and not pushed to center from upper left
  const leftRef = maxWidth ? -maxWidth : 0;
  const topRef = maxHeight ? -maxHeight : 0;

  // place anywhere on canvas
  const x = randomNumberBetween(leftRef, canvas.width);
  const y = randomNumberBetween(topRef, canvas.height);

  // set variable if canvas width is greater than maxWidth then use maxWidth
  const widthRef = maxWidth || canvas.width;
  const heightRef = maxHeight || canvas.height;

  // keep constrained to maxWidth and maxHeight if defined
  const width = randomNumberBetween(0, widthRef);
  const height = Math.floor(Math.random() * heightRef);

  // console.log(`bounds: ${x}, ${y}, ${width}, ${height}`);

  return { x, y, width, height };
}
