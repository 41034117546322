import React from 'react';
import ButtonContainer from './ButtonContainer';
import './Footer.css';
import Logo from './Logo';


export default function Footer(props: any) {
  console.log('Footer props', props);
  const onRefresh = () => {
    props.onRefresh();
  };

  const onDownload = () => {
    props.onDownload();
  };

  return (
    <footer className="App-footer">
      <ButtonContainer
        onRefresh={onRefresh}
        onDownload={onDownload}
      />
    </footer>
  );
}
