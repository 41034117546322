import { randomNumberBetween } from "../../Utils/Random";

export function setTriLineWidth(options) {
  const { lineWidth, lineWidthRange } = options;

  if (lineWidth) {
    return lineWidth;
  } else if (lineWidthRange) {
    return setLineWidthByRange(options);
  } else {
    return 1;
  }
}

// fixed or percentage of side
function setLineWidthByRange(options) {
  const { lineWidthRange, side } = options;
  const { lower, upper, style } = lineWidthRange;

  if (style === 'fixed') {
    const lineWidthLower = lower || 1;
    const lineWidthUpper = upper || 3;

    return randomNumberBetween(lineWidthLower, lineWidthUpper);
  } else if (style === 'percentage') {
    const lineWidthLower = lower || 11;
    const lineWidthUpper = upper || 13;
    const randomPercent =
      randomNumberBetween(lineWidthLower, lineWidthUpper) / 100;
    return side.side * randomPercent;
  } else {
    randomNumberBetween(lower, upper);
  }
}