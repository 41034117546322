import { randomHexColor } from "../Utils/Color";

export default function drawBackground(ctx, options, canvas) {
  const { width, height, color, fontSize, fontFamily, text } = options;

  // creates snapshot of current state of canvas
  ctx.save();

  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);
  ctx.fillStyle = randomHexColor()
  ctx.font = `${fontSize}px ${fontFamily}`;
  ctx.fillText(text, canvas.width / 2, canvas.height / 2);

  // reset canvas to previous state
  ctx.restore();
}
