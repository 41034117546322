// export a function that returns a random number between two numbers and can be negative
export function randomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// multiply a number by 1 or -1 randomly
export function randomPositiveOrNegative(number) {
  return Math.random() < 0.5 ? number : -number;
}

// function that imitates a coin flip
export function coinFlip(odds = 0.5) {
  return Math.random() < odds ? true : false;
}

// function that selects a random item from an array
export function randomItemFromArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

// shuffle and loop array
export function shuffleArray(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
