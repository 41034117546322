import { setTriHeight } from "./Height";
import { setTriPosition } from "./Position";
import { setTriSide } from "./Side";

export function setCoords(options) {
  const { x, y } = setTriPosition(options);
  options.x = x;
  options.y = y;

  const side = setTriSide(options.side, options);
  const triHeight = setTriHeight(side);

  return {
    // throw everything into this
    x: x,
    y: y,
    // side: side,
    triHeight: triHeight,

    // top middle
    x0: 0,
    y0: 0,

    xOuter: 0,
    yOuter: 0 - triHeight,

    xInner: 0,
    yInner: 0 - triHeight / 2, // must be half
  };
}
