export function rotationBySides(options) {
  const rotation = options.rotation;
  if (rotation === 'random') {
    return Math.floor(defaultRotation());
  } else {
    return options.rotation || defaultRotation();
  }
}

function defaultRotation() {
  return Math.PI / 3;
}
