// function that gets the absolute difference between two numbers
export function getAbsoluteDifference(number1, number2) {
  return Math.abs(number1 - number2);
}

// function that gets average of an array of numbers
export function getAverage(array) {
  return array.reduce((a, b) => a + b) / array.length;
}

// fibonacci sequence
export function fibonacci(n) {
  return n < 1 ? 0 : n <= 2 ? 1 : fibonacci(n - 1) + fibonacci(n - 2);
}

// return number negative or positive
export function getNegativeOrPositive(number) {
  return Math.random() < 0.5 ? -number : number;
}