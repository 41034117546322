import { setTriFillStyleVariables } from "./FillStyle";
import { setTriSideVariables } from "./Side";
import { setTriStrokeStyleVariables } from "./StrokeStyle";

export function addOptionsVariables(options) {
  const { side, fillStyle, strokeStyle } = options;

  const variableOptions = {};

  // side
  const sideVariables = setTriSideVariables(side.variables);
  if (sideVariables) {
    variableOptions.side = {
      ...side,
      side: sideVariables,
    };
  }

  // fillStyle
  const fillStyleVariables = setTriFillStyleVariables(
    fillStyle.variables
  );
  if (fillStyleVariables) {
    variableOptions.fillStyle = {
      ...fillStyle,
      fillStyle: fillStyleVariables,
    };
  }

  // strokeStyle
  const strokeStyleVariables = setTriStrokeStyleVariables(
    strokeStyle.variables
  );
  if (strokeStyleVariables) {
    variableOptions.strokeStyle = {
      ...strokeStyle,
      strokeStyle: strokeStyleVariables,
      variables: {
        ...strokeStyle.variables,
        random: false,
      }
    };
  }

  return {
    ...options,
    ...variableOptions,
  };
}


