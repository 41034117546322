import React from 'react';
import demos from '../Demos/ActiveDemos';
import downloadCanvas from '../Utils/Download';
import { randomItemFromArray } from '../Utils/Random';
import './Canvas.css';
import addLayer from './Layer';

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.onCanvasClicked = this.onCanvasClicked.bind(this);
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate() {
    const canvas = this.canvasRef.current;
    if (this.props.printCanvas) {
      downloadCanvas(canvas);
      this.props.onCanvasPrinted();
    }

    if (this.props.refreshCanvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setTimeout(() => {
        this.draw();
      }, 10);
    }
  }

  draw() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    // clear canvas since hooks make this fire twice in development
    // https://stackoverflow.com/a/61286259
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.randomDemo(canvas, ctx);
    // addLayer(canvas, ctx, { type: 'brand' });
  }

  randomDemo(canvas, ctx) {
    const demo = randomItemFromArray(demos());
    demo(canvas, ctx);
  }

  onCanvasClicked() {
    this.props.onCanvasClicked();
  }

  render() {
    if (this.props.printCanvas) {
      console.log('printing canvas');
    }

    return (
      <div
        className="App-canvas-container"
        onClick={this.onCanvasClicked}
      >
        <canvas
          className="App-canvas"
          ref={this.canvasRef}
          // width={100}
          // height={100}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      </div>
    );
  }
}

export default Canvas;
