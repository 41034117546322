import { shuffleArray } from "./Random";

// export function to generate a random hexcolor
export function randomHexColor() {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

// https://stackoverflow.com/a/49974627
export function rgba2hex(orig) {
  var a,
    isPercent,
    rgb = orig
      .replace(/\s/g, '')
      .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || '').trim(),
    hex = rgb
      ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
        (rgb[2] | (1 << 8)).toString(16).slice(1) +
        (rgb[3] | (1 << 8)).toString(16).slice(1)
      : orig;

  if (alpha !== '') {
    a = alpha;
  } else {
    a = 1;
  }
  // // multiply before convert to HEX
  // a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  // hex = hex + a;

  return '#' + hex.toUpperCase();
}

// function shuffle array of colors and return in colors object
export function assignColorsFromArray(options = {}) {
  const { colors } = options;

  if (options.shuffle) {
    const shuffledColors = shuffleArray(colors);
    return {
      primary: shuffledColors[0],
      secondary: shuffledColors[1],
      tertiary: shuffledColors[2],
      fourth: shuffledColors[3],
      fifth: shuffledColors[4],
    };
  }

  return {
    primary: colors[0],
    secondary: colors[1],
    tertiary: colors[2],
    fourth: colors[3],
    fifth: colors[4],
  };
}

// make darker shade of hex color
// github copilot
export function darkenHexColor(hex, percent) {
  const num = parseInt(hex.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) - amt,
    B = ((num >> 8) & 0x00ff) - amt,
    G = (num & 0x0000ff) - amt;

  return (
    "#" +
    (0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255))
      .toString(16)
      .slice(1)
  );
}

// https://stackoverflow.com/a/13532993
export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR =
    R.toString(16).length == 1
      ? '0' + R.toString(16)
      : R.toString(16);
  var GG =
    G.toString(16).length == 1
      ? '0' + G.toString(16)
      : G.toString(16);
  var BB =
    B.toString(16).length == 1
      ? '0' + B.toString(16)
      : B.toString(16);

  return '#' + RR.toUpperCase() + GG.toUpperCase() + BB.toUpperCase();
}