// create button component with click event
import React from 'react';
import Button from './Button';
import './ButtonContainer.css';

export default function ButtonContainer(props: any) {

  const onClickRefresh = () => {
    props.onRefresh();
  }

  const onClickDownload = () => {
    props.onDownload();
  }

  return (
    <div className="App-button-container">
      <Button text={'Refresh'} onClick={onClickRefresh} />
      <Button text={'Download'} onClick={onClickDownload} />
    </div>
  );
}
