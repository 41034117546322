const skipLogging = false;

export function setTimeStamp(str) {
  if (!skipLogging) console.log(`start - ${str} [${currentTime()}]`);
  return Date.now();
}

export function logSeconds(timestamp, str) {
  const seconds = (Date.now() - timestamp) / 1000;
  let logString = `done - ${seconds}s: ${str}  [${currentTime()}]`;

  if (seconds < 1) {
    logString += ' 🐇';
  } else if (seconds > 10) {
    logString += ' 🐢';
  }

  if (!skipLogging) console.log(logString);
}


// new date and get hour minute and seconds
function currentTime() {
  const date = new Date();
  const hour = date.getHours() === 0 ? 12 : date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${hour}:${minute}:${second}`;
}
